<div class="modal-header">
    <div class="modal-title" data-translate>Are you sure?</div>
    <button type="button" class="close btn" (click)="cancel()">
        <i class="fa-solid fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <span>{{'By confirming, you will edit the expiration date to:'|translate}}</span>
    <div class="input-group">
        <input type="text" readonly class="form-control inputelem" placeholder="yyyy-mm-dd" name="dp" [required]="true" [minDate]="minDate" [maxDate]="maxDate" [(ngModel)]="args.expiration_date" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" />
        <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button">
            <i class="fas fa-calendar"></i>
        </button>
    </div>
</div>
<div class="modal-footer">
    <button id="modal-action-ok" class="btn btn-primary" (click)="confirm()">
        <i class="fa-solid fa-check"></i>
        <span>{{'Yes'|translate}}</span>
    </button>
    <button id="modal-action-cancel" class="btn btn-outline-secondary" (click)="cancel()">
        <i class="fa-solid fa-times"></i>
        <span>{{'No'|translate}}</span>
    </button>
</div>
