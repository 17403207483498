<div id="SubmissionErrors" class="alert alert-secondary mb-2" role="alert">
    <div *ngIf="!hasPreviousStepValue">
        <div *ngIf="!areReceiversSelectedValue">
            <p>{{'You must select at least one recipient.'|translate}}</p>
        </div>
    </div>
    <div *ngIf="!hasNextStepValue">
        <div *ngIf="utilsService.isUploading(uploads)">
            <span>{{'Waiting for the file(s) to finish uploading.'|translate}}</span>
            <i class="fa-solid fa-sync gly-spin"></i>
        </div>
    </div>
    <src-step-error [navigation]="navigation" [field_id_map]="field_id_map" [stepForms]=stepForms [submission]="submission" (goToStep)="goToStep.emit()" [stepForm]="stepForm" [displayStepErrors]="displayStepErrors"></src-step-error>
</div>
